<template>
	<div class="qr-entries-table pb-3">
		<v-data-table
			class="overflow-x elevation-1"
			height="100%"
			item-key="id"
			hide-default-footer
			:headers="headers"
			:items="items"
			:loading="loading"
			:page.sync="options.page"
			:options="options"
			:server-items-length="options.itemsLength"
			@click:row="handleRowClick"
		>
			<!--  ХЭДЕР ТАБЛИЦЫ -->
			<template v-slot:top="{ pagination, options, updateOptions }">
				<v-data-footer
					class="justify-end pa-3 border-bottom"
					:pagination="pagination"
					:options="options"
					@update:options="updateOptions"
					items-per-page-text=""
					:items-per-page-options="[]"
					disable-items-per-page
					:disable-pagination="loading"
					:show-first-last-page="true"
				>
					<template v-slot:prepend>
						<div class="d-flex flex-sm-grow-1 align-center justify-start">
							<!-- Кнопка создания -->
							<v-btn
								class="mr-5"
								color="primary"
								@click="showModalCreate = true"
								small
								depressed
							>
								<v-icon class="mr-1" small>mdi-plus</v-icon>
								<span class="body-2 font-weight-light">Добавить устройство</span>
							</v-btn>
							<!-- Сброс фильтров -->
							<v-btn
								class="mr-5 text-lowercase"
								color="primary"
								small
								outlined
								@click="resetFilters"
							>
								<span class="body-2 font-weight-light">
									<span class="text-capitalize">Сбросить</span> фильтры
								</span>
							</v-btn>
						</div>
					</template>
				</v-data-footer>
			</template>
			<template v-slot:[`body.prepend`]>
				<tr>
					<!-- Поиск по названию -->
					<td>
						<v-text-field
							v-model="filter.name"
							placeholder="Поиск по названию"
							class="border-all mr-5"
							solo
							dense
							flat
							hide-details
							@change="updateQuery"
						>
						</v-text-field>
					</td>
					<!-- Фильтр Адрес -->
					<td>
						<v-combobox
							v-model="filter.house_ids"
							placeholder="Адрес"
							class="text-caption border-all"
							solo
							multiple
							item-text="apartment_address"
							item-value="id"
							:return-object="false"
							:items="houses"
							:filter="addressFilter"
							@change="updateQuery"
							dense
							hide-details
							flat
						>
							<template v-slot:selection="{ item, index }">
								<span v-if="index === 0 && item.length > 1">
									{{ currentHouses(item) }}
								</span>
								<span
									v-if="index === 1"
									class="grey--text text-caption"
								>&nbsp;
									(+ еще {{ filter.house_ids.length - 1 }})
								</span>
							</template>
						</v-combobox>
					</td>
						<td>
							<v-autocomplete
								v-model="filter.type_id"
								class="border-all"
								solo
								dense
								flat
								hide-details
								item-text="name"
								item-value="id"
								:items="[{ name: 'Все', id: null }, ...devices_types]"
								@change="updateQuery"
								:disabled="loading"
							>
							</v-autocomplete>
					</td>
				</tr>
			</template>
			<template v-slot:[`item.houses`]="{ item }">
				<td>{{ item.house.apartment_address }}</td>
			</template>

		</v-data-table>
		<!-- ОКНО СОЗДАНИЯ ВЪЕЗДА -->
		<QrTableGroupsModal
			v-if="showModalCreate"
			@update-info="updateQuery"
			@close-modal="showModalCreate = false"
		/>
		<!-- ОКНО РЕДАКТИРОВАНИЯ ВЪЕЗДА -->
		<QrTableGroupsModal
			v-if="showModalEdit"
			:toEdit="toEdit"
			@update-group="loadGroup($event)"
			@update-info="updateQuery"
			@close-modal="showModalEdit = false"
		/>
	</div>
</template>

<script>
import { timestampToDdmmyyyy } from "@/helpers/helpers";
import { mapGetters } from "vuex";

import QrTableGroupsModal from "@/components/QrNextGroupsModal";

export default {
	name: "QrNextGroups",

	components: {
		QrTableGroupsModal,
	},

	data() {
		return {
			toEdit: null,
			firstFetch: false,
			items: [],
			filter: {
				name: "",
				house_ids: null,
				type_id: null,
			},
			filtersQuery: {
				"type_id": "type_id",
				"house_ids": "house_ids",
				"name": "name",
			},
			headers: [
				{ text: "Название", value: "name", sortable: false },
				{ text: "Адреса", value: "houses", sortable: false },
				{ text: "Тип", value: "type.name", sortable: false },
				{ text: "Кол-во устройств", value: "devices.length", sortable: false },
			],
			options: {
				page: +this.$route.query.page || 1,
				itemsPerPage: 10,
				pageStart: 1,
				pageStop: 0,
				pageCount: 0,
				itemsLength: 0,
			},
			devices_types: [
				{
					id: 1, 
					name: 'Подъезд', 
					system_name: 'porch', 
				},
        {
					id: 2, 
					name: 'Ворота в паркинг', 
					system_name: 'gate_parking', 
				}, 
				{
						id: 3, 
						name: 'Шлагбаумы', 
						system_name: 'barriers', 
				}, 
				{
						id: 4, 
						name: 'Калитки', 
						system_name: 'gate', 
				}, 
				{
						id: 5, 
						name: 'Двери в паркинг', 
						system_name: 'door_parking', 
				},
			],
			loading: true,
			showModalCreate: false,
			showModalEdit: false,
		};
	},

	computed: {
		...mapGetters({
			catalog: "catalog/GET_CATALOG",
			role: "auth/GET_USER_ROLE_VUEX",
			houses: "ksk/GET_NEWQR_EXIST",
		}),
		// computedItems() {
		// 	return this.items.filter(e =>
		// 		e.name.toLowerCase().includes(this.filter.name.toLowerCase())
		// 	);
		// },
	},

	watch: {
		options: {
			handler() {
				if(this.firstFetch) {
					const query = { ...this.$route.query, page: this.options.page };
					this.$router.replace({ query });
					this.loadData(query);
				}
			},
			deep: true,
		},
	},

	methods: {
		timestampToDdmmyyyy,

		currentHouses(item) {
			if(item.length > 1) {
				const result = this.houses.find(({ id }) => id === item);
				return result?.apartment_address
			}
		},
		handleRowClick(e) {
			console.log('e', e)
			this.loadGroup(e.id)
		},
		loadGroup(id) {
			this.loading = true
			this.$api.ksk.load_group(id)
			.then(res => {
				this.toEdit = res
				this.showModalEdit = true
			})
			.catch(error => {
				throw error;
			})
			.finally(() => {
				this.firstFetch = true
				this.loading = false
			});
		},
		async resetFilters() {
			for (const key in this.filter) {
				this.filter[key] = "";
			}
			this.options.page = 1
			this.$router.replace({ query: null });
			await this.loadData({});
		},

		async loadData(params) {
			this.loading = true;
			console.log('houses', this.houses)
			if(!params.hasOwnProperty('house_ids') || !params.house_ids.length) {
				params.house_ids = []
				this.houses.forEach(house => {
					params.house_ids.push(house.id);
				});
			}
			return this.$api.ksk
				.load_groups(params)
				.then(res => {
					this.items = res.records;
					this.options.pageCount = res.pages;
					this.options.itemsLength = res.pages * 10;
					if(res.pages < this.options.page) {
						this.options.page = 1;
					}
				})
				.catch(error => {
					throw error;
				})
				.finally(() => {
					this.loading = false;
					this.firstFetch = true
				});
		},

		async updateQuery(getQuery = false) {
			const query = Object.entries(this.filter).reduce((acc, [key, val]) => {
				if (!val) return acc;
				return { ...acc, [this.filtersQuery[key]]: val };
			}, {});

			if (getQuery === true) {
				return query;
			}

			this.$router.replace({ query: { ...query, page: this.options.page } });
			await this.loadData(query);
		},
		// ? Конец методов таблицы
	},

	async created() {
		this.$store.dispatch("ksk/load_houses_all");
		this.$store.dispatch("ksk/load_employee");
	},

	async mounted() {
		try {
			const query = { ...this.$route.query, page: this.options.page };
			if(typeof query.house_ids == 'string') {
				let house = query.house_ids
				delete query['house_ids']
				query.house_ids = [house]
				this.filter.house_ids = query.house_ids
			}
			await this.loadData(query);
			this.loading = false;
		} catch (error) {
			console.error(error);
		}
	},
};
</script>

<style lang="scss" scoped>
.whitelist-table {
	height: 100vh;
}
</style>
