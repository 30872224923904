var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"qr-entries-table pb-3"},[_c('v-data-table',{staticClass:"overflow-x elevation-1",attrs:{"height":"100%","item-key":"id","hide-default-footer":"","headers":_vm.headers,"items":_vm.items,"loading":_vm.loading,"page":_vm.options.page,"options":_vm.options,"server-items-length":_vm.options.itemsLength},on:{"update:page":function($event){return _vm.$set(_vm.options, "page", $event)},"click:row":_vm.handleRowClick},scopedSlots:_vm._u([{key:"top",fn:function(ref){
var pagination = ref.pagination;
var options = ref.options;
var updateOptions = ref.updateOptions;
return [_c('v-data-footer',{staticClass:"justify-end pa-3 border-bottom",attrs:{"pagination":pagination,"options":options,"items-per-page-text":"","items-per-page-options":[],"disable-items-per-page":"","disable-pagination":_vm.loading,"show-first-last-page":true},on:{"update:options":updateOptions},scopedSlots:_vm._u([{key:"prepend",fn:function(){return [_c('div',{staticClass:"d-flex flex-sm-grow-1 align-center justify-start"},[_c('v-btn',{staticClass:"mr-5",attrs:{"color":"primary","small":"","depressed":""},on:{"click":function($event){_vm.showModalCreate = true}}},[_c('v-icon',{staticClass:"mr-1",attrs:{"small":""}},[_vm._v("mdi-plus")]),_c('span',{staticClass:"body-2 font-weight-light"},[_vm._v("Добавить устройство")])],1),_c('v-btn',{staticClass:"mr-5 text-lowercase",attrs:{"color":"primary","small":"","outlined":""},on:{"click":_vm.resetFilters}},[_c('span',{staticClass:"body-2 font-weight-light"},[_c('span',{staticClass:"text-capitalize"},[_vm._v("Сбросить")]),_vm._v(" фильтры ")])])],1)]},proxy:true}],null,true)})]}},{key:"body.prepend",fn:function(){return [_c('tr',[_c('td',[_c('v-text-field',{staticClass:"border-all mr-5",attrs:{"placeholder":"Поиск по названию","solo":"","dense":"","flat":"","hide-details":""},on:{"change":_vm.updateQuery},model:{value:(_vm.filter.name),callback:function ($$v) {_vm.$set(_vm.filter, "name", $$v)},expression:"filter.name"}})],1),_c('td',[_c('v-combobox',{staticClass:"text-caption border-all",attrs:{"placeholder":"Адрес","solo":"","multiple":"","item-text":"apartment_address","item-value":"id","return-object":false,"items":_vm.houses,"filter":_vm.addressFilter,"dense":"","hide-details":"","flat":""},on:{"change":_vm.updateQuery},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
var item = ref.item;
var index = ref.index;
return [(index === 0 && item.length > 1)?_c('span',[_vm._v(" "+_vm._s(_vm.currentHouses(item))+" ")]):_vm._e(),(index === 1)?_c('span',{staticClass:"grey--text text-caption"},[_vm._v("  (+ еще "+_vm._s(_vm.filter.house_ids.length - 1)+") ")]):_vm._e()]}}]),model:{value:(_vm.filter.house_ids),callback:function ($$v) {_vm.$set(_vm.filter, "house_ids", $$v)},expression:"filter.house_ids"}})],1),_c('td',[_c('v-autocomplete',{staticClass:"border-all",attrs:{"solo":"","dense":"","flat":"","hide-details":"","item-text":"name","item-value":"id","items":[{ name: 'Все', id: null } ].concat( _vm.devices_types),"disabled":_vm.loading},on:{"change":_vm.updateQuery},model:{value:(_vm.filter.type_id),callback:function ($$v) {_vm.$set(_vm.filter, "type_id", $$v)},expression:"filter.type_id"}})],1)])]},proxy:true},{key:"item.houses",fn:function(ref){
var item = ref.item;
return [_c('td',[_vm._v(_vm._s(item.house.apartment_address))])]}}],null,true)}),(_vm.showModalCreate)?_c('QrTableGroupsModal',{on:{"update-info":_vm.updateQuery,"close-modal":function($event){_vm.showModalCreate = false}}}):_vm._e(),(_vm.showModalEdit)?_c('QrTableGroupsModal',{attrs:{"toEdit":_vm.toEdit},on:{"update-group":function($event){return _vm.loadGroup($event)},"update-info":_vm.updateQuery,"close-modal":function($event){_vm.showModalEdit = false}}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }